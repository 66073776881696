<template lang="pug">
  .inventory-management.container-fluid
    AppOverlayLoader.loader(:state="inventoryManagementLoading")
    Filters.filters_sticky(
      v-show="isFiltersVisible"
      :class="{ visible: isPeriodAndButtonFiltersVisible }"
      :shops-loading="shopsLoading"
      :car-classes-loading="carClassesLoading"
      :filters="filters"
      :shops-items="shopsItems"
      :car-classes="carClassesItems"
      @change-filters="applyFilters"
      @change-shop="changedShopHandler"
      @change:month-picker-enabled="handleMonthPickerEnabled"
      @closed:car-classes="handleCloseCarClassesDropdown"
      :is-close-dropdown-on-click-outside="isCloseDropdownOnClickOutside"
    )
    .d-flex.justify-content-between.p-t-5.p-b-5.sticky-buttons(
      v-if="isFiltersVisible"
      :class="{ visible: isPeriodAndButtonFiltersVisible }"
    )
      div
        AppButton.inventory-for-long-period(
          title="inventory_management.inventory_long_period.btn_title"
          :disabled="!hasEditPermission('siflp')"
          @click="openSetInventoryLongPeriodModal"
        )
        AppButton.inventory-for-future-days.m-l-10(
          v-if="!isNicolasEnabled"
          title="inventory_management.inventory_for_future.btn_title"
          :disabled="!hasEditPermission('siffd')"
          @click="openAutoSetInventoryForFutureModal"
        )
      div
        AppIconButton.import-inventory(
          useAppIcon
          icon="reply"
          title="inventory_management.import_inventory"
          v-b-tooltip.hover="$t('inventory_management.tooltip.import_inventory')"
          :disabled="!hasEditPermission('manual_inventory_import')"
          @click="handleClickImportInventory"
        )
        AppIconButton.reimport-inventory(
          useAppIcon
          icon="redo"
          title="inventory_management.resend_inventory"
          v-b-tooltip.hover="$t('inventory_management.tooltip.resend_inventory')"
          :disabled="!hasEditPermission('resend_inventory')"
          @click="handleClickReimportInventory"
        )
        AppSaveButton.m-l-10(
          v-if="hasEditPermission()"
          short
          title="actions.save_changes"
          :disabled="!allowSave"
          @save="saveChanges"
        )
    SyncInProgressNotification(
      :shown="isFiltersVisible && isInventorySyncInProgress"
      :class="{ visible: isPeriodAndButtonFiltersVisible }"
    )

    .inventory-management-wrapper
      .table-wrapper
        .sticky.p-t-10(
          :class="{ visible: isFiltersVisible && isPeriodAndButtonFiltersVisible, sync_notification: isInventorySyncInProgress }"
        )
          CalendarHeader(
            ref="calendarHeader"
            :editable="filtersValid"
            :grouped-date-range="groupedDateRange"
            :sale-stop-days="saleStopDays"
            :is-inventories-changed="!isEmpty(totalChangedInventories)"
            :current-date="currentDate.value"
            :is-inventories-present="isInventoriesPresent"
            :is-filters-visible="isFiltersVisible"
            :car-classes="carClassesForPage"
            @change-sale-stop-days="changeSaleStopDayInventoryManagement"
            @reset-changed-inventories="resetChangedInventories"
            @toggle-filter-visibility="toggleFilterVisibility"
          )
        Transition(
          name="fade-slideright"
          tag="div"
          class="transition-container"
        )
          CarClassRows(
            v-if="isInventoriesPresent"
            key="car-class-rows"
            :grouped-date-range="groupedDateRange"
            :inventories="inventories"
            :car-classes-for-page="carClassesForPage"
            :shared-inventory-enabled="sharedInventoryEnabled"
            :changed-inventories="totalChangedInventories"
            :invalid-inventories="invalidInventories"
            :current-date="currentDate.value"
            @change-inventory="changeInventory"
          )
</template>

<script>
  // store modules
  import inventoryManagementModule from "@/config/store/inventory_management"
  import shopMatchingModule from "@/config/store/matching/shop"
  import carClassMatchingModule from "@/config/store/matching/car_class"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withPermissions from "@/mixins/withPermissions"
  import withWebSocket from "@/mixins/withWebSocket"
  import withModal from "@/mixins/withModal"
  import withContactOtaNotification from "@/mixins/withContactOtaNotification"
  import withConfirmation from "@/mixins/withConfirmation"
  import withInventorySyncMethods from "@/mixins/inventories/withInventorySyncMethods"
  import withSaleStopDaysMethods from "@/mixins/inventories/withSaleStopDaysMethods"
  import withResendNotification from "@/mixins/withResendNotification"

  // components
  import ImportInventoryData from "./ImportInventoryData"
  import ResendInventoryModal from "@/components/InventoryManagement/ResendInventoryModal"
  import SetInventoryForLongPeriod from "./SetInventoryForLongPeriod"
  import AutoSetInventoryForFutureDays from "./SetForFutureDays"

  // misc
  import { bus } from "@/config"
  import { SERIALIZER_VIEW_FILTERS, APP_DATEPICKER_START_DATE, APP_DATEPICKER_END_DATE } from "@/config/constants"
  import { format as dateFormat, startOfDay } from "date-fns"
  import { isEmpty, map, groupBy, get, values, some, reduce, forEach, isEqual, transform, every } from "lodash-es"
  import { appDebounce, currentDateInTimeZone } from "@/helpers/common"
  import { getDateRange, getFormattedDateRange } from "@/helpers/dates"
  import { mapGetters } from "vuex"
  import getLongPeriodChangedInventories from "./SetInventoryForLongPeriod/getLongPeriodChangedInventories"
  import {
    invalidInventories,
    availableInventory,
    shopAvailable,
    sharedAvailable,
    cleanedInventoryData
  } from "./inventoryValidation"

  const inventoryManagementMixin = withStoreModule(inventoryManagementModule, {
    name: "inventoryManagement",
    readers: {
      inventories: "items",
      filters: "filters",
      inventoryManagementLoading: "loading",
      carClassesForPage: "carClassesForPage",
      processingShopIds: "processingShopIds",
      hiddenSaleStopDropdowns: "hiddenSaleStopDropdowns"
    },
    mutations: {
      updateProcessingShop: "UPDATE_PROCESSING_SHOP",
      setInventories: "SET_ITEMS",
      setFilters: "SET_FILTERS",
      setCarClassesForPage: "SET_CAR_CLASSES_FOR_PAGE"
    },
    actions: {
      resendInventory: "RESEND_INVENTORY",
      fetchInventoriesAction: "FETCH_ITEMS",
      updateInventories: "UPDATE_ITEMS",
      switchHiddenSaleStopDropdown: "SWITCH_HIDDEN_SALE_STOP_DROPDOWN",
      importInventory: "IMPORT_INVENTORY"
    }
  })

  const shopMatchingMixin = withStoreModule(shopMatchingModule, {
    name: "shopMatching",
    readers: { shopsLoading: "loading", shopsItems: "items" },
    actions: { fetchShops: "FETCH_ITEMS" }
  })

  const carClassMatchingMixin = withStoreModule(carClassMatchingModule, {
    name: "carClassMatching",
    readers: { carClassesLoading: "loading", carClassesItems: "items" },
    actions: { fetchCarClasses: "FETCH_ITEMS" }
  })

  const DISTANCE_LENGTH = 86

  export default {
    components: {
      CarClassRows: () => import("./CarClassRows"),
      CalendarHeader: () => import("./CalendarHeader"),
      Filters: () => import("./Filters"),
      Notifications: () => import("./Notifications"),
      AppSaveButton: () => import("@/components/elements/AppButton/Save"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader"),
      AppButton: () => import("@/components/elements/AppButton"),
      SyncInProgressNotification: () => import("@/pages/InventoryManagement/SyncInProgressNotification.vue"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    mixins: [
      withInventorySyncMethods({}),
      inventoryManagementMixin,
      shopMatchingMixin,
      carClassMatchingMixin,
      withPermissions,
      withWebSocket,
      withModal,
      withConfirmation,
      withContactOtaNotification,
      withSaleStopDaysMethods,
      withResendNotification
    ],

    beforeRouteLeave(to, from, next) {
      this.beforeRouteLeaveHandler({
        to,
        next,
        isChanges: this.inventoriesChanged,
        exitHandler: () => {
          this.setInventories({})
        }
      })
    },

    data() {
      return {
        // [
        //   {
        //     id: 8,
        //     date: "2020/04/08",
        //     inventory: {
        //       available_inventory: 1,
        //       blocked_inventory: 3,
        //       shared_inventory_limit: 0,
        //       shop_inventory_limit: 0,
        //       booked_inventories_by_ota: {
        //         1: 2,
        //         2: 2
        //       },
        //       sale_stop_by_ota: {
        //         1: { ota_id: 1, sale_stop: false, disabled: true },
        //         2: { ota_id: 2, sale_stop: true, disabled: false }
        //       }
        //     },
        //     car_class_id: 66
        //   }
        // ]
        changedInventories: {},
        isFiltersVisible: true,
        isManualFiltersVisible: true,
        isPeriodAndButtonFiltersVisible: false,
        filtersVisibleIntervalID: null,
        carClassesCachedFilter: [],
        longPeriodChangedInventories: {},
        currentDate: {
          value: null,
          intervalID: null
        },
        isCloseDropdownOnClickOutside: true
      }
    },

    async mounted() {
      this.setInventories({})
      await this.fetchShopsAndUpdateFilters()

      if (!isEmpty(this.selectedShop)) await this.fetchCarClassesAndUpdateFilters()

      this.fetchInventories()

      this.currentDate.intervalID = window.setInterval(() => {
        this.currentDate.value = startOfDay(currentDateInTimeZone())
      }, 1000)

      this.filtersVisibleIntervalID = setInterval(() => {
        const calendarHeaderElement = this.$refs.calendarHeader?.$el
        if (calendarHeaderElement) {
          const rect = calendarHeaderElement.getBoundingClientRect()
          const distance = rect.top

          if (distance < DISTANCE_LENGTH && this.isManualFiltersVisible) {
            this.isFiltersVisible = false
            this.isManualFiltersVisible = false
          }

          if (distance > DISTANCE_LENGTH && this.isManualFiltersVisible) {
            this.isFiltersVisible = true
          }
        }
      }, 300)

      bus.on("import-inventory", this.importInventory)
      bus.on("set-long-period-inventory", this.handleSetLongPeriodInventory)
      bus.on("close-modal", this.setFailedInventories)
    },

    created() {
      if (this.useContactOtaNotification) {
        this.handleClickImportInventory = () => {
          this.showContactOtaNotification(this.openImportInventoryModal)
        }
      } else {
        this.handleClickImportInventory = this.openImportInventoryModal
      }
    },

    beforeDestroy() {
      window.clearInterval(this.filtersVisibleIntervalID)
      window.clearInterval(this.currentDate.intervalID)
      bus.off("import-inventory", this.importInventory)
      bus.off("set-long-period-inventory", this.handleSetLongPeriodInventory)
      bus.off("close-modal", this.setFailedInventories)
    },

    computed: {
      ...mapGetters(["isNicolasEnabled"]),

      inventoriesChanged() {
        return !isEmpty(this.totalReallyChangedInventories)
      },

      hasProcessingShops({ selectedShop }) {
        return !isEmpty(selectedShop) && this.processingShopIds.includes(selectedShop.id)
      },

      filtersValid() {
        return !isEmpty(this.selectedShop) && !isEmpty(this.selectedCarClasses)
      },

      formattedDateRange({ filters: { start_date, end_date } }) {
        return getFormattedDateRange(start_date, end_date)
      },

      isInventoriesPresent() {
        return !isEmpty(this.inventories)
      },

      allowSave() {
        return (
          !this.inventoryManagementLoading &&
          (!isEmpty(this.saleStopDaysToSet) || !isEmpty(this.totalChangedInventories)) &&
          !this.isInvalidChangedInventories &&
          !this.isInventorySyncInProgress &&
          !this.hasProcessingShops
        )
      },

      invalidInventories({ inventories, totalChangedInventories }) {
        return invalidInventories(inventories, totalChangedInventories)
      },

      isInvalidChangedInventories({ invalidInventories, totalChangedInventories }) {
        // invalidInventories = {
        //   "60,2022/01/01": false,
        //   "60,2022/01/02": false,
        //   "60,2022/01/03": true
        // }
        return some(
          map(totalChangedInventories, (changedInventory, key) => {
            const isExpired = new Date(changedInventory.date) > new Date()
            return invalidInventories[key] && !this.isAllActiveOtasSaleStopped(changedInventory) && isExpired
          })
        )
      },

      activeOtaIds({ inventories }) {
        return reduce(
          inventories,
          (obj, { ota_data }, carClassId) => {
            obj[carClassId] = []
            forEach(values(ota_data), ({ ota_id, ...rest }) => {
              if (every(rest, otaDataItem => !otaDataItem)) {
                obj[carClassId].push(ota_id)
              }
            })
            return obj
          },
          {}
        )
      },

      selectedCarClasses({ filters }) {
        return filters.car_classes
      },

      selectedShop({ filters }) {
        return filters.shop
      },

      dateRange({ filters }) {
        return getDateRange(filters.start_date, filters.end_date)
      },

      groupedDateRange() {
        return groupBy(this.dateRange, date => dateFormat(date, "yyyy-MM"))
      },

      sharedInventoryEnabled() {
        return !isEmpty(get(this.selectedShop, "inventory_group"))
      },

      totalChangedInventories({ longPeriodChangedInventories, changedInventories, groupedInventories }) {
        return transform(
          { ...longPeriodChangedInventories, ...changedInventories },
          (obj, changedInventoryData, key) => {
            const initialInventory = groupedInventories[key]
            const { inventory: changedInventory } = changedInventoryData

            // compare changed inventory data with initial inventory data and
            // add to totalChangedInventories if anything was changed or with failed exports
            if (this.isInventoryChanged(changedInventory, initialInventory)) obj[key] = changedInventoryData
            return obj
          },
          {}
        )
      },

      totalReallyChangedInventories({ totalChangedInventories, groupedInventories }) {
        return transform(
          totalChangedInventories,
          (obj, changedInventoryData, key) => {
            const initialInventory = groupedInventories[key]
            const { inventory: changedInventory } = changedInventoryData

            // compare changed inventory data with initial inventory data and
            // add to totalChangedInventories if anything was really changed
            if (this.isInventoryChanged(changedInventory, initialInventory, false)) obj[key] = changedInventoryData
            return obj
          },
          {}
        )
      },

      groupedInventories({ inventories }) {
        return reduce(
          inventories,
          (obj, { inventory_by_days }, carClassID) => {
            forEach(inventory_by_days, (inventoryData, date) => {
              obj[[carClassID, date]] = inventoryData
            })
            return obj
          },
          {}
        )
      },

      saleStopDaysToSet() {
        return reduce(
          this.saleStopDays,
          (res, saleStop, key) => {
            if (saleStop.value && saleStop.changed) {
              return [...res, key]
            }
            return res
          },
          []
        )
      },

      otas() {
        return this.$store.getters.otasWithoutNicolas
      }
    },

    methods: {
      isEmpty,

      async fetchCarClassesAndUpdateFilters() {
        await this.fetchCarClasses(this.carClassesParams(this.selectedShop.id)).then(() => {
          const activeCarClasses = this.carClassesItems.map(({ id }) => id)

          if (!isEmpty(this.selectedCarClasses)) {
            const selectedCarClasses = this.selectedCarClasses.filter(({ id }) => activeCarClasses.includes(id))
            this.setFilters({ car_classes: selectedCarClasses })
          }
        })
      },

      async fetchShopsAndUpdateFilters() {
        await this.fetchShops({ pagination: { _disabled: true }, matched_only: true, synced_only: true }).then(() => {
          const activeShops = this.shopsItems.map(({ id }) => id)

          if (!isEmpty(this.selectedShop)) {
            const selectedShop = activeShops.includes(this.selectedShop.id) ? this.selectedShop : {}

            if (isEmpty(selectedShop)) {
              this.setFilters({ shop: {}, car_classes: [] })
            } else {
              this.setFilters({ shop: selectedShop })
            }
          }
        })
      },

      isAllActiveOtasSaleStopped({ car_class_id, inventory: { sale_stop_ota_ids } }) {
        return isEqual(this.activeOtaIds[car_class_id], sale_stop_ota_ids)
      },

      isInventoryChanged(changedInventory, initialInventory, withNotified = true) {
        if (isEmpty(initialInventory)) return false

        const isChanged =
          changedInventory.blocked_inventory !== initialInventory.blocked_inventory ||
          changedInventory.shop_inventory_limit !== initialInventory.shop_inventory_limit ||
          changedInventory.shared_inventory_limit !== initialInventory.shared_inventory_limit ||
          !isEqual(changedInventory.sale_stop_ota_ids.sort(), initialInventory.sale_stop_ota_ids.sort())

        return withNotified ? isChanged || changedInventory.export_is_notified : isChanged
      },

      fetchInventories() {
        if (this.filtersValid) {
          this.fetchInventoriesAction().then(() => {
            this.resetChangedInventories()
            this.setFailedInventories()
          })
        }
      },

      setFailedInventories() {
        this.changedInventories = transform(
          this.inventories,
          (obj, value, carClassID) => {
            forEach(value.inventory_by_days, (inventory, date) => {
              if (inventory.export_is_notified) {
                obj[[carClassID, date]] = this.failedInventoryObject(carClassID, date, inventory)
              }
            })
          },
          {}
        )
        this.setLogoutConfirm(this.inventoriesChanged)
      },

      failedInventoryObject(carClassID, date, inventory) {
        return {
          id: inventory.id,
          date: date,
          car_class_id: +carClassID,
          inventory: {
            ...cleanedInventoryData(inventory),
            available_inventory: availableInventory(
              false,
              inventory,
              sharedAvailable(false, inventory),
              shopAvailable(false, inventory)
            )
          }
        }
      },

      withConfirmAction(callback) {
        this.isCloseDropdownOnClickOutside = false
        this.$conditionalConfirm({
          useConfirm: this.inventoriesChanged,
          title: this.$t("inventory_management.continue_without_save"),
          handler: () => {
            callback()
            this.isCloseDropdownOnClickOutside = true
          },
          cancelHandler: () => {
            this.isCloseDropdownOnClickOutside = true
          }
        })
      },

      resetHeaderScroll() {
        this.$refs.calendarHeader.resetHeaderScroll()
      },

      applyFilters(filters, fetch = true) {
        this.withConfirmAction(() => {
          this.resetChangedInventories()
          this.setFilters(filters)
          if (isEmpty(this.selectedCarClasses)) this.resetHeaderScroll()
          if (filters.car_classes.length === 0) {
            this.carClassesCachedFilter = []
          }

          if (this.filtersValid && fetch) {
            this.debouncedFetchInventories()
          }
        })
      },

      handleCloseCarClassesDropdown() {
        if (this.filtersValid) {
          if (!isEqual(this.carClassesCachedFilter, this.filters.car_classes)) this.debouncedFetchInventories()
          this.carClassesCachedFilter = this.filters.car_classes
        } else {
          this.setInventories({})
          if (this.filters.car_classes.length === 0) {
            this.setCarClassesForPage([])
          }
        }
      },

      openSetInventoryLongPeriodModal() {
        this.$conditionalConfirm({
          useConfirm: this.inventoriesChanged,
          title: this.$t("inventory_management.continue_without_save"),
          handler: () => {
            this.resetChangedInventories()
            this.$openModal({
              title: this.$t("inventory_management.inventory_long_period.modal_title"),
              component: SetInventoryForLongPeriod,
              size: "large",
              closeOnEsc: false,
              closeOnClick: false,
              emitClose: true
            })
          }
        })
      },

      openAutoSetInventoryForFutureModal() {
        this.$openModal({
          title: this.$t("inventory_management.inventory_for_future.auto_set_inventory_for_future_days"),
          component: AutoSetInventoryForFutureDays,
          size: "large",
          closeOnEsc: false,
          closeOnClick: false,
          useConfirm: true
        })
      },

      changeInventory(inventory) {
        const { date, car_class_id } = inventory
        const changedInventories = { ...this.changedInventories, [[car_class_id, date]]: inventory }

        if (!isEmpty(inventory.inventory)) {
          this.changedInventories = changedInventories
          this.setLogoutConfirm(this.inventoriesChanged)
        }

        this.updateHeaderSaleStopDays(this.changedInventories)
      },

      saveChanges() {
        this.updateInventories({
          changed_inventories: values(this.totalChangedInventories),
          sale_stop_days: this.saleStopDaysToSet
        }).then(() => {
          bus.emit("inventory-saved")
          this.resetChangedInventories()
        })
      },

      carClassesParams(shopId) {
        return {
          pagination: { _disabled: true },
          shop_id: shopId,
          synced_only: true,
          sources_active_only: true,
          serializer_view: shopId && SERIALIZER_VIEW_FILTERS
        }
      },

      changedShopHandler(shopId) {
        this.withConfirmAction(() => {
          this.resetChangedInventories()
          this.setInventories({})
          if (isEmpty(this.selectedCarClasses)) this.resetHeaderScroll()
          if (this.filters.car_classes.length === 0) this.carClassesCachedFilter = []
          this.setCarClassesForPage([])

          this.fetchCarClasses(this.carClassesParams(shopId))
          this.fetchImportsAction(shopId)
          this.isFiltersVisible = true
          this.isManualFiltersVisible = true
          this.isPeriodAndButtonFiltersVisible = false
        })
      },

      handleClickReimportInventory() {
        this.showResendNotification(this.openReimportInventory)
      },

      openReimportInventory() {
        this.$openModal({
          title: this.$t("inventory_management.inventory_import.resend_inventory_data"),
          component: ResendInventoryModal,
          size: "large",
          props: {
            references: this.shopsItems,
            referenceType: "shop",
            otaList: this.otas,
            processingReferenceIds: this.processingShopIds,
            resendAction: this.resendInventory
          }
        })
      },

      handleSetLongPeriodInventory: async function(settingsObject) {
        const filters = {
          start_date: settingsObject.date[APP_DATEPICKER_START_DATE],
          end_date: settingsObject.date[APP_DATEPICKER_END_DATE],
          shop: settingsObject.shop,
          car_classes: settingsObject.carClasses,
          monthPickerEnabled: false
        }

        if (this.selectedShop?.id !== settingsObject.shop.id) {
          this.setFilters(filters)
          // need for fetch options to carClasses dropdown
          await this.fetchCarClasses({
            pagination: { _disabled: true },
            shop_id: filters.shop.id,
            synced_only: true,
            serializer_view: filters.shop.id && SERIALIZER_VIEW_FILTERS
          })
        } else {
          this.setFilters(filters)
        }

        await this.fetchInventoriesAction()
        this.longPeriodChangedInventories = getLongPeriodChangedInventories(
          settingsObject,
          this.inventories,
          this.isNicolasEnabled
        )

        this.updateHeaderSaleStopDays(this.longPeriodChangedInventories)
      },

      openImportInventoryModal() {
        this.$openModal({
          title: this.$t("inventory_management.inventory_import.import_inventory_data"),
          component: ImportInventoryData,
          size: "large",
          props: {
            inProgressReferenceItemIds: this.inProgressReferenceItemIds
          }
        })
      },

      resetChangedInventories() {
        this.changedInventories = {}
        this.longPeriodChangedInventories = {}
        this.setLogoutConfirm(false)
      },

      handleMonthPickerEnabled(monthPickerEnabled) {
        this.withConfirmAction(() => {
          this.setFilters({ monthPickerEnabled })

          if (this.filtersValid) this.debouncedFetchInventories()
        })
      },

      toggleFilterVisibility() {
        this.isFiltersVisible = !this.isFiltersVisible
        this.isPeriodAndButtonFiltersVisible = !this.isPeriodAndButtonFiltersVisible
      },

      debouncedFetchInventories: appDebounce(function() {
        this.fetchInventories()
      }, 100)
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/helpers.sass"

  .inventory-management
    position: relative

    .inventory-for-long-period
      background: $default-purple
      color: $default-white
      padding: 0 20px
      width: 114px

    .inventory-for-future-days
      background: #2e9fad
      color: $default-white
      padding: 0 20px
      width: 115px

    .notification
      position: sticky
      z-index: 1
      margin-bottom: 0
      margin-top: 0

      &.visible
        top: 214px

    .filters_sticky
      position: sticky
      background-color: #fff
      padding-top: 20px
      z-index: 1

      &.visible
        top: 75px

    .sticky-buttons
      position: sticky
      background-color: #fff
      z-index: 1

      &.visible
        top: 170px

      @media screen and (max-width: 1200px)
        top: 243px

    .reimport-inventory
      width: 150px

    ::v-deep
      .import-inventory
        background-color: $default-white
        color: $default-purple
        border: 1px solid $default-purple
        font-size: 0.8rem
        font-weight: normal
        height: 33px
        width: 150px
        padding: 5px 10px
        margin-right: 10px

        svg
          path
            fill: $default-purple


    .save-inventories-row
      align-items: center
      display: flex
      justify-content: space-between

      p
        font-size: 0.8rem
        margin: 0


    .table-wrapper
      position: relative

    &-wrapper
      position: relative

      .sticky
        position: sticky
        top: 75px
        z-index: 10
        background: $default-white
        max-width: 100%
        width: fit-content

        &.visible
          top: 214px

          &.sync_notification
            top: 242px

        @media screen and (max-width: 1200px)
          top: 311px

        +ie-only
          position: inherit

    .loader
      z-index: 10000002
</style>
